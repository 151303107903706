export class StorageServiceController {
  constructor() {
  }

  public set(name: string, data: Object) {
    localStorage.setItem(name, JSON.stringify(data));
  }

  public get(name: string) {
    return JSON.parse(localStorage.getItem(name));
  }

  public remove(name: string) {
    localStorage.removeItem(name);
  }
}

export const StorageService = new StorageServiceController();
